@font-face {
  font-family: "proxima_reg";
  src: url("../assets/fonts/ProximaNova_Regular.otf");
  src: format("opentype");
}

@font-face {
  font-family: "proxima_light";
  src: url("../assets/fonts/Proxima_Nova_Alt_Light.otf");
  src: format("opentype");
}

@font-face {
  font-family: "proxima_bold";
  src: url("../assets/fonts/Proxima_Nova_Bold.otf");
  src: format("opentype");
}

@font-face {
  font-family: "Cormorant_bold_italic";
  src: url("../assets/fonts/Cormorant_BoldItalic.otf");
  src: format("opentype");
}
::placeholder {
  color: black;
}

.proxima_bold {
  font-family: "proxima_bold";
}

.proxima_reg {
  font-family: "proxima_reg";
}

.proxima_light {
  font-family: "proxima_light";
}

.cormorant_bold_italic {
  font-family: "Cormorant_bold_italic";
}
.history_card {
  min-width: 330px !important;
  border: none !important;
}

.loading {
  font-family: "proxima_reg";
}
