.promo-image {
  max-width: 80vw;
  max-height: 65vh;
  margin: 40px 30px 10px 30px;
}

.arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 90px;
  border: 2px transparent solid;
}
.arrow:hover {
  border: 2px lightgray solid;
  background-color: #efefef;
  border-radius: 50px;
}

.arrow_img {
  height: 72px;
  align-self: center;
}

@media screen and (max-width: 767px) {
  .promo-image {
    width: 300px;
  }
}
