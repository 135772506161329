.request-container {
  /* display: flex; */
  flex-wrap: wrap;
  /* justify-content: center; */
  /* align-items: center; */
  /* margin-top: 5%; */
  /* margin-bottom: 10%; */
}

.request-item {
  /* transform-origin: 100% 100%; */
  /* margin: 0px; */
  /* flex: 1 2; */
  /* width: 33vw; */
  margin: 0px;
  /* height: 300px; */
  /* margin-left: 2%; */
  /* margin-right: 2%; */
  /* max-width: 400px; */
  /* padding-top: 10%; */
  margin-bottom: 2%;
}

.request-square {
  height: 272px;
  width: 100%;

  /* max-width: 300px; */

  object-fit: cover;
  /* width: 100%; */
  /* padding-bottom: 20px; */
  /* width: 100%;
  height: 80%; */
}

.request-title {
  left: 0px;

  /* font-size: 1.6em; */
  color: black;
  letter-spacing: 2px;
  /* padding-bottom: -10%; */
}

a {
  text-decoration: none;
  color: black;
  font-size: 1rem;
}

.active {
  background-color: lightgray;
  margin: 0px;
  padding: 3px;
}

.dropdown {
  width: 300px;
}

.reservation-square {
  height: 30%;
}
