@font-face {
  font-family: "Corm_bold_italic";
  src: url("../assets/fonts/Cormorant_BoldItalic.otf");
  src: format("opentype");
}
@font-face {
  font-family: "proxima_reg";
  src: url("../assets/fonts/ProximaNova_Regular.otf");
  src: format("opentype");
}

@font-face {
  font-family: "proxima_med";
  src: url("../assets/fonts/Proxima_Nova_Medium.ttf");
  src: format("opentype");
}

@font-face {
  font-family: "proxima_thin";
  src: url("../assets/fonts/Proxima_Nova_Alt_Light.otf");
  src: format("opentype");
}

.thanks {
  font-family: "Corm_bold_italic";
  font-size: 36px;
}

.center {
  font-family: "proxima_thin";
  text-align-last: center;
  margin-bottom: "30px";
}

.button {
  color: black;
  border: black 3px solid;
  margin: 3px;
  padding: 12px;
  width: 234px;
  text-align: center;
  font-family: "proxima_med";
}

.button:hover {
  text-decoration: none;
  color: black;
  background-color: lightgray;
}
