/** @format */

@font-face {
  font-family: "Cormorant_bold_italic";
  src: url("../assets/fonts/Cormorant_BoldItalic.otf");
  src: format("opentype");
}
@font-face {
  font-family: "Cormorant_medium_italic";
  src: url("../assets/fonts/Cormorant-MediumItalic.otf");
  src: format("opentype");
}
@font-face {
  font-family: "proxima_reg";
  src: url("../assets/fonts/ProximaNova_Regular.otf");
  src: format("opentype");
}

@font-face {
  font-family: "proxima_med";
  src: url("../assets/fonts/Proxima_Nova_Medium.ttf");
  src: format("truetype");
}

@font-face {
  font-family: "proxima_light";
  src: url("../assets/fonts/Proxima_Nova_Alt_Light.otf");
  src: format("truetype");
}

.title {
  font-family: "proxima_med";
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 1.37px;
}

.sub_title {
  font-family: "proxima_light";
  font-size: 16px;
  letter-spacing: 1.37px;
}
.details {
  font-family: "proxima_light";
  font-size: 14px;
  letter-spacing: 0.29px;
  text-align: center;
  width: 65%;
}

.calendar_button {
  font-size: 36px;
  font-family: "Cormorant_bold_italic";
}

button:focus {
  /* border: 1px solid blue; */
  box-shadow: none !important;
}

.card {
  margin-bottom: 10px;
}

.loading {
  font-family: "proxima_reg";
}

.placeHolder {
  font-family: "Cormorant_medium_italic";
}
