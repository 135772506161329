@font-face {
  font-family: "proxima_reg";
  src: url("../assets/fonts/ProximaNova_Regular.otf");
  src: format("opentype");
}

@font-face {
  font-family: "proxima_bold";
  src: url("../assets/fonts/Proxima_Nova_Bold.otf");
  src: format("opentype");
}

@font-face {
  font-family: "proxima_ex_bold";
  src: url("../assets/fonts/Proxima_Nova_Extrabold.otf");
  src: format("opentype");
}

@font-face {
  font-family: "Corm_semi_bold_italic";
  src: url("../assets/fonts/Cormorant-SemiBoldItalic.otf");
  src: format("opentype");
}

.login_button {
  font-family: "Corm_semi_bold_italic";
}

.account:focus {
  outline: none;
}

.info {
  font-family: "proxima_reg";
}

.account_buttons {
  font-family: "proxima_reg";
}

.changePass {
  font-family: "proxima_reg";
}

.navbar_item:hover {
  color: red;
}

.footer {
  /* flex: 1; */
  background-color: rgba(0, 0, 0);
  opacity: 1;
  max-height: 100px;
  width: 100%;
  color: white;
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.account_toggle {
  position: absolute;
  right: 76px;
  top: 150px;
  background-color: black;
  z-index: 2;
  min-width: 320px;
  width: 25vw;
  padding-bottom: 10px;
}
.termsandPrivacyMobile{
  display:none
}
.termsandPrivacyDesktop{
  display: flex;
    flex-direction: column;
}
.PrivacyContainer{
  margin: 50px 250px 50px 150px;
  overflow-wrap: break-word
}
.privacyBackButton{
  color:black;
  margin-left: 150px;
  font-size: 20px;
  align-items: center;
  display: flex;
}
.privacyBackButton:hover{
  color: darkred
}
.navLinks{
  color: white;
  text-decoration: underline;
}
.navLinks:hover{
  color: grey;
  text-decoration: underline;
}
.bs-navbar-collapse{
  margin: 0px 76px
}
@media screen and (max-width: 992px) {
  .bs-navbar-collapse{
    margin: 0px 0px
  }
  .PrivacyContainer{
    margin: 25px 60px 50px 60px;
    overflow-wrap: break-word
  }
  .privacyBackButton{
    margin-left: 60px
  }
  .nav_spacer, .termsandPrivacyDesktop {
    display: none;
  }
  .termsandPrivacyMobile{
    display:flex;
    justify-content: space-evenly;
    margin-bottom: 5px;
    align-items: center;
    height: 25px;
  }
  .footer {
    padding-left: 10px;
  }
  .account_toggle {
    top: 320px;
  }
}
@media screen and (max-width: 545px) {
  .account_toggle {
    top: 420px;
    left: 0px;
  }
}
