@font-face {
  font-family: "proxima_light";
  src: url("../assets/fonts/Proxima_Nova_Alt_Light.otf");
  src: format("opentype");
}

.filter_section {
  text-align: left;
}

.filter > span {
  font-family: "proxima_light";
  padding-left: 5px;
}

.filter {
  margin-bottom: 3px;
}

.filter_button {
  width: 200px;
  height: 50px;
  color: white;
  background-color: black;
  border: 3px white solid;
  margin: 30px 40px;
  align-self: center;
  border-radius: 3px;
}

.map_info {
  font-family: "proxima_light";
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 2px white solid;
  padding: 25px 10px 10px 10px;
}

.filter_row {
  padding: 10px 0px 50px 100px;
  justify-content: center;
}

@media screen and (max-width: 992px) {
  .filter_row {
    padding: 10px 0px 50px 10px;
  }
}

@media screen and (max-width: 768px) {
  .filter_row {
    padding: 10px 0px 10px 50px;
  }
  .filter_section {
    padding-bottom: 20px;
  }
  .filter_button {
    margin: 0px 10px 20px 10px;
  }
}
